import {IMGPW} from '@/config';
import {Link} from '@/i18n/routing';
import {useTranslations} from 'next-intl';

export default function() {
  const t = useTranslations('index');
  return (
    <>
      <section className="bg-linear-to-b from-70% from-white to-30% to-greyscale-gray-lighter py-20">
        <div className="section-container">
          <div className="bg-white shadow-2xl">
            <div className="flex flex-wrap md:flex-row flex-col">
              <div className="md:w-1/2 flex-1">
                <div className="relative py-12 px-6">
                  <h4 className="text-xl font-medium tracking-tight text-primary mb-2">
                    {t('WhatIsSection.section-title')}
                  </h4>
                  <h1 className="text-4xl font-medium tracking-tight text-greyscale-body mb-3">
                    {t('WhatIsSection.h1-1')}
                    <strong>{t('WhatIsSection.h1-2')}</strong>
                    {t('WhatIsSection.h1-3')}
                  </h1>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('WhatIsSection.p-1-1')}
                    <strong>{t('WhatIsSection.p-1-2')}</strong>
                    {t('WhatIsSection.p-1-3')}
                  </p>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('WhatIsSection.p-2-1')}
                    <strong>{t('WhatIsSection.p-2-2')}</strong>
                  </p>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('WhatIsSection.p-3-1')}
                    <strong>{t('WhatIsSection.p-3-2')}</strong>
                    {t('WhatIsSection.p-3-3')}
                  </p>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('WhatIsSection.p-4-1')}
                    <strong>{t('WhatIsSection.p-4-2')}</strong>
                    {t('WhatIsSection.p-4-3')}
                    <strong>{t('WhatIsSection.p-4-4')}</strong>
                  </p>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('WhatIsSection.p-5-1')}
                  </p>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('WhatIsSection.p-6-1')}
                    <strong>{t('WhatIsSection.p-6-2')}</strong>
                    {t('WhatIsSection.p-6-3')}
                    <strong>{t('WhatIsSection.p-6-4')}</strong>
                    {t('WhatIsSection.p-6-5')}
                  </p>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('WhatIsSection.p-7-1')}
                    <a href="https://itunes.apple.com/it/app/winelivery/id1067851965?mt=8">
                      iOS
                    </a>{' '}
                    {t('WhatIsSection.p-7-2')}
                    <a href="https://play.google.com/store/apps/details?id=com.winelivery1.oos&hl=it">
                      Android
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 flex-1">
                <div className="bg-what-is bg-no-repeat bg-center bg-cover h-[100%] min-h-[500px]" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-greyscale-gray-lighter pb-20">
        <div className="mx-auto xl:w-[1170px] xl:max-w-[1170px] px-4 xl:px-0">
          <h3 className="text-center text-3xl font-bold mb-3 pt-3 md:pt-4 text-greyscale-title">
            {t('WhatIsSection.discover_bar')}
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              className="border rounded-sm bg-cover bg-center grid grid-cols-2 bg-greyscale-pink"
              style={{
                backgroundImage: `url(${IMGPW}/Consegna_30min.jpg)`,
              }}
            >
              <div />
              <div className="p-5 text-right">
                <h4 className="text-xl font-bold tracking-tight text-greyscale-body mb-4 p-0">
                  {t('WhatIsSection.cities_express')}
                </h4>
                <Link
                  // @ts-expect-error
                  href={`/#cities_section`}
                  className="button btn-md leading-10 btn-primary mx-0!"
                >
                  {t('WhatIsSection.discover')}
                </Link>
              </div>
            </div>

            <div
              className="border rounded-sm bg-cover bg-center grid grid-cols-2 bg-greyscale-pink"
              style={{
                backgroundImage: `url(${IMGPW}/Store_Locator.jpg)`,
              }}
            >
              <div className="p-5 text-left">
                <h4 className="text-xl font-bold tracking-tight text-greyscale-body mb-4 p-0">
                  {t('WhatIsSection.cities_bar')}
                </h4>
                <Link
                  href={'/bar'}
                  className="button btn-md leading-10 btn-primary mx-0!"
                >
                  {t('WhatIsSection.discover')}
                </Link>
              </div>
              <div />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
