import { createRoot } from "react-dom/client";
import {SearchProduct} from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import logger from '../logger';
import {CartItem} from '@/stores/cart/types';
import {CheckoutState} from '@/stores/checkout/types';

export const initBasket = (cart: CartItem[]): void => {
  try {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    let tp_rtrgt_items = '';
    const list: any[] = [];
    cart.forEach((item) => {
      list.push(item.product.id + '|' + item.quantity);
    });
    tp_rtrgt_items = list.join(',');

    const tp_rtrgt_segment = '';

    const tp_rtrgt_random = Math.random() * 10000000000000000;
    let tp_rtrgt_url =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://ad.ad-srv.net/retarget?a=50338&version=1&event=basket&cat=16252';
    tp_rtrgt_url += '&segment=' + tp_rtrgt_segment;
    tp_rtrgt_url += '&items=' + tp_rtrgt_items;

    const element =
      '<div id="tp_rtrgt_div_' +
      tp_rtrgt_random +
      '" style="position:absolute; visibility:hidden; left:0; top:0; width:1px; height:1px; border:0"><iframe id="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" name="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" src="' +
      tp_rtrgt_url +
      '" scrolling="no" width="1" height="1"></iframe></div>';

    const root = createRoot(document.getElementById('targeting-360-div')!);
    root.render(<div dangerouslySetInnerHTML={{__html: element}} />);
  } catch (err) {
    logger.error(err);
  }
};

export const trackCategoryPage = (
  category?: string,
  subcategory?: string,
): void => {
  try {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    let tp_rtrgt_segment = '';

    if (category) {
      tp_rtrgt_segment = category;
      if (subcategory) {
        tp_rtrgt_segment += '-' + subcategory;
      }
    }

    const tp_rtrgt_random = Math.random() * 10000000000000000;
    let tp_rtrgt_url =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://ad.ad-srv.net/retarget?a=50338&version=1';
    tp_rtrgt_url += '&segment=' + tp_rtrgt_segment;

    // @ts-ignore
    const element =
      '<div id="tp_rtrgt_div_' +
      tp_rtrgt_random +
      '" style="position:absolute; visibility:hidden; left:0; top:0; width:1px; height:1px; border:0"><iframe id="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" name="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" src="' +
      tp_rtrgt_url +
      '" scrolling="no" width="1" height="1"></iframe></div>';

    const root = createRoot(document.getElementById('targeting-360-div')!);
    root.render(<div dangerouslySetInnerHTML={{__html: element}} />);
  } catch (err) {
    logger.error(err);
  }
};

export const trackProductPage = (product: SearchProduct): void => {
  try {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    const tp_rtrgt_items = product.id;

    const tp_rtrgt_segment =
      product.category.slug + '-' + product.subcategory.slug;

    const tp_rtrgt_random = Math.random() * 10000000000000000;
    let tp_rtrgt_url =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://ad.ad-srv.net/retarget?a=50338&version=1&event=view&cat=16252';
    tp_rtrgt_url += '&segment=' + tp_rtrgt_segment;
    tp_rtrgt_url += '&items=' + tp_rtrgt_items;

    // @ts-ignore
    const element =
      '<div id="tp_rtrgt_div_' +
      tp_rtrgt_random +
      '" style="position:absolute; visibility:hidden; left:0; top:0; width:1px; height:1px; border:0"><iframe id="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" name="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" src="' +
      tp_rtrgt_url +
      '" scrolling="no" width="1" height="1"></iframe></div>';

    const root = createRoot(document.getElementById('targeting-360-div')!);
    root.render(<div dangerouslySetInnerHTML={{__html: element}} />);
  } catch (err) {
    logger.error(err);
  }
};

export const trackHomePage = (): void => {
  try {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    const tp_rtrgt_random = Math.random() * 10000000000000000;
    const tp_rtrgt_url =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://ad.ad-srv.net/retarget?a=50338&version=1';

    // @ts-ignore
    const element =
      '<div id="tp_rtrgt_div_' +
      tp_rtrgt_random +
      '" style="position:absolute; visibility:hidden; left:0; top:0; width:1px; height:1px; border:0"><iframe id="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" name="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" src="' +
      tp_rtrgt_url +
      '" scrolling="no" width="1" height="1"></iframe></div>';

    const root = createRoot(document.getElementById('targeting-360-div')!);
    root.render(<div dangerouslySetInnerHTML={{__html: element}} />);
  } catch (err) {
    logger.error(err);
  }
};

export const purchase = (checkout: CheckoutState): void => {
  try {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    let tp_rtrgt_items = '';
    let list: any[] = [];
    checkout.items.forEach((item) => {
      list.push(item.product.id + '|' + item.quantity);
    });
    tp_rtrgt_items = list.join(',');

    const tp_rtrgt_segment = '';

    const tp_rtrgt_random = Math.random() * 10000000000000000;
    let tp_rtrgt_url =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://ad.ad-srv.net/retarget?a=50338&version=1&event=transaction&cat=16252';
    tp_rtrgt_url += '&segment=' + tp_rtrgt_segment;
    tp_rtrgt_url += '&items=' + tp_rtrgt_items;

    // @ts-ignore
    const element =
      '<div id="tp_rtrgt_div_' +
      tp_rtrgt_random +
      '" style="position:absolute; visibility:hidden; left:0; top:0; width:1px; height:1px; border:0"><iframe id="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" name="tp_rtrgt_iframe_' +
      tp_rtrgt_random +
      '" src="' +
      tp_rtrgt_url +
      '" scrolling="no" width="1" height="1"></iframe></div>';

    const root = createRoot(document.getElementById('targeting-360-div')!);
    root.render(<div dangerouslySetInnerHTML={{__html: element}} />);
  } catch (err) {
    logger.error(err);
  }
};
