import {IMGPW} from '@/config';
import {Link} from '@/i18n/routing';
import {useTranslations} from 'next-intl';
import Image from 'next/image';

export default function DottedItems() {
  const t = useTranslations('index');

  return (
    <div className="mt-1 mb-4 flex flex-col gap-3">
      <div className="flex gap-3 items-center">
        <Image
          alt={t('MainSection.delivery-30-min')}
          src={`${IMGPW}/crono.png`}
          width={32}
          height={32}
        />
        <h2 className="text-lg mb-0">
          {t('MainSection.delivery-30-min')}
        </h2>
      </div>
      <div className="flex gap-3 items-center">
        <Image
          alt={t('MainSection.ideal-temperature')}
          src={`${IMGPW}/termometro.png`}
          width={32}
          height={32}
        />
        <h2 className="text-lg mb-0">
          {t('MainSection.ideal-temperature')}
        </h2>
      </div>
      <div className="flex gap-3 items-center">
        <Image
          alt={t('MainSection.straight-from-producer')}
          src={`${IMGPW}/produttore.png`}
          width={32}
          height={32}
        />
        <h2 className="text-lg mb-0">
          <span>{t('MainSection.straight-from-producer')} </span>
          <Link href="/direttamente-dal-produttore">
            {t('MainSection.discover-more')}
          </Link>
        </h2>
      </div>
    </div>
  );
}
