import {IMGPW} from '@/config';
import {Link} from '@/i18n/routing';
import {useTranslations} from 'next-intl';
import Image from 'next/image';

function CategoryCard(args: {
  category: string;
  label: string;
  description: string;
  picSrc: string;
}) {
  const t = useTranslations('index');
  return (
    <div>
      <div className="relative">
        <Image
          src={`${IMGPW}/${args.picSrc}`}
          alt={args.label}
          width={0}
          height={0}
          className="w-full h-auto rounded-sm"
          sizes="100vw"
        />
      </div>
      <div className="text-center mt-4 flex flex-col gap-1">
        <h4 className="text-xl font-bold tracking-tight text-greyscale-body mb-0 p-0">
          {args.label}
        </h4>
        <p className="text-base font-medium tracking-tight text-greyscale-label mb-0 p-0">
          {args.description}
        </p>
        <Link
          href={{pathname: '/shop', query: {category: args.category}}}
          className="text-base font-medium tracking-tight"
        >
          {t('CategorySection.discover')}
        </Link>
      </div>
    </div>
  );
}

export default function CategorySection() {
  const t = useTranslations('index');

  return (
    <section className="mt-20">
      <div className="section-container">
        <h2 className="text-3xl font-bold tracking-tight text-greyscale-title text-center mb-4">
          {t('CategorySection.what-do-we-bring')}
        </h2>

        <div className="flex flex-wrap gap-8 justify-center">
          <div className="sm:w-1/4">
            <CategoryCard
              label={t('CategorySection.wine')}
              category="wine"
              description={t('CategorySection.wine-desc')}
              picSrc="categoria_vino_home.jpg"
            />
          </div>
          <div className="sm:w-1/4">
            <CategoryCard
              label={t('CategorySection.beer')}
              category="beer"
              description={t('CategorySection.beer-desc')}
              picSrc="categoria_birra_home.jpg"
            />
          </div>
          <div className="sm:w-1/4">
            <CategoryCard
              label={t('CategorySection.drink')}
              category="drink"
              description={t('CategorySection.drink-desc')}
              picSrc="categoria_drink_home.jpg"
            />
          </div>
        </div>

        <div className="mb-20 mt-12">
          <div className="mb-12">
            <div className="flex flex-wrap gap-8 md:flex-row flex-col">
              <div className="md:w-1/2 flex-1 relative" style={{minHeight: 150}}>
                <div
                  className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover"
                  style={{
                    backgroundImage: `url(${IMGPW}/banner_gift_home.jpg)`,
                  }}
                />
              </div>
              <div className="md:w-1/2 flex-1 flex flex-col justify-between">
                <div>
                  <h4 className="text-xl font-bold tracking-tight text-greyscale-body mb-2 p-0">
                    {t('CategorySection.gift.title')}
                  </h4>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('CategorySection.gift.desc')}
                  </p>
                </div>
                <div>
                  <Link
                    href={`/fai-un-regalo`}
                    className="m-0 button btn-md leading-10 btn-primary"
                  >
                    {t('CategorySection.gift.cta')}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-greyscale-gray-lighter">
            <div className="flex flex-wrap md:flex-row flex-col-reverse">
              <div className="md:w-1/2 flex-1">
                <div className="py-4 px-6">
                  <h4 className="text-xl font-bold tracking-tight text-greyscale-body mb-2 p-0">
                    {t('CategorySection.sparkling.title')}
                  </h4>
                  <p className="text-base font-medium tracking-tight text-greyscale-label mb-2 p-0">
                    {t('CategorySection.sparkling.desc')}
                  </p>
                  <Link
                    href={{
                      pathname: '/shop',
                      query: {category: 'wine', subcategory: 'sparkling'},
                    }}
                    className="m-0 button leading-10 btn-md btn-primary"
                  >
                    {t('CategorySection.sparkling.cta')}
                  </Link>
                </div>
              </div>
              <div
                className="md:w-1/2 flex-1"
                style={{ minHeight: 150 }}
              >
                <div
                  className="bg-no-repeat bg-center bg-cover h-[100%] min-h-[150px]"
                  style={{
                    backgroundImage: `url(${IMGPW}/banner_cold_home.jpg)`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
