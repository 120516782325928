'use client';

import {Link} from '@/i18n/routing';
import {useTranslations} from 'next-intl';
import {useSearchParams} from 'next/navigation';
import {useEffect, useState} from 'react';
import logger from '../../../../../../../logger';
import {ILocation} from '@/models/location';
import useSession from '@/stores/session/useSession';
import useAddressSearch from '../hook';
import LastAddress from './LastAddress';
import Predictions from './Predictions';
import SavedAddresses from './SavedAddresses';
import StreetNumberForm from './StreetNumberForm';

interface Props {
  q: string;
  onChangeQ: (q: string) => void;
  onChangeAddress: (location: ILocation) => void;
  showSavedAddresses?: boolean;
  showLastAddress?: boolean;
  bodyLoggedMessage?: boolean;
  isFocused?: boolean;
}

export default function AddressSelect({
  q,
  onChangeQ,
  onChangeAddress,
  showSavedAddresses = false,
  showLastAddress = false,
  bodyLoggedMessage = true,
  isFocused = false,
}: Props) {
  const t = useTranslations('common');

  const query = useSearchParams();

  const {user} = useSession();

  const [currentLocation, setCurrentLocation] = useState<ILocation>();
  const [askForStreetNumber, setAskForStreetNumber] = useState(false);
  const {predictions, findLocationFromPrediction} = useAddressSearch(q);

  useEffect(() => {
    setAskForStreetNumber(false);
  }, [q]);

  async function handlePredictionClick(
    prediction: google.maps.places.AutocompletePrediction,
  ) {
    onChangeQ(prediction.description);
    try {
      const location = await findLocationFromPrediction(prediction);
      handleSelect(location);
    } catch (e) {
      logger.error(e);
    }
  }

  function handleStreetNumberSubmit(streetNumber: string) {
    // TODO: add a street number validation.
    const [address, ...rest] = currentLocation!.formattedAddress!.split(', ');
    const formattedAddress = [address, streetNumber, ...rest].join(', ');
    onChangeQ(formattedAddress);
    handleSelect({
      ...currentLocation,
      address: currentLocation?.address || '',
      city: currentLocation?.city || '',
      coordinates: currentLocation?.coordinates || {lat: 0, lng: 0},
      streetNumber,
      formattedAddress,
    });
  }

  function handleSelect(location: ILocation) {
    setCurrentLocation(location);
    if (!location.streetNumber) {
      setAskForStreetNumber(true);
      return;
    }
    onChangeAddress(location);
  }

  const bodyNotLoggedText = t.rich('address.bodyNotLogged', {
    login: (c) => (
      <Link
        href={{
          pathname: '/auth/signin',
          ...(query && {query: query.toString()}),
        }}
      >
        {c}
      </Link>
    ),
  });

  if (askForStreetNumber) {
    return (
      <div className="p-4">
        <StreetNumberForm onSubmit={handleStreetNumberSubmit} />
      </div>
    );
  }

  return (
    <>
      {q.trim().length === 0 && showLastAddress && bodyLoggedMessage && (
        <div className="text-base font-medium tracking-tight text-greyscale-label m-0 p-0">
          {!user ? (
            <div className="mt-4 px-4">{bodyNotLoggedText}</div>
          ) : (
            <div className="mt-4 px-4">{t('address.bodyLogged')}</div>
          )}
        </div>
      )}

      <Predictions predictions={predictions} onSelect={handlePredictionClick} />
      {isFocused && showLastAddress && <LastAddress onSelect={handleSelect} />}
      {isFocused && showSavedAddresses && (
        <SavedAddresses onSelect={handleSelect} />
      )}
    </>
  );
}
